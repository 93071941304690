<div class="mdl-color--amber color-header clearfix">
    <div class="color-explanation mdl-color-text--white">
        <h4>Verification</h4>
        <h6>We will need to verify your email</h6>
    </div>
</div>
<div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-tablet mdl-cell--12-col-phone mdl-color--grey-100">
        <div class="left-info-box">
            <!--<h5><i class="material-icons">format_align_left</i> Help</h5>-->
            <p></p>
        </div>
    </div>
    <div class="mdl-cell mdl-cell--9-col mdl-cell--12-col-tablet mdl-cell--12-col-phone r-content">
        <div class="body-content">
            <div class="mdl-card mdl-shadow--2dp change-width">
                <div class="mdl-card__title">
                    <h2 class="mdl-card__title-text">Email Verification Needed</h2>
                </div>
                <div class="mdl-card__supporting-text">
                    <p><strong>Thank you for registering with Tangerine.</strong> We sent you an "activate account" email to the address you provided. 
                    Please check your junk/spam folder if you don't see the email shortly. You will need to click 
                    on the link in the email to activate your account or paste the link into your browser.</p>
                </div>
            </div>
        </div>
    </div>
</div>