<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<mat-card class="tangy-full-width">
    <mat-card-content>
        <form class="tangy-input-width" novalidate #addRoleForm="ngForm">
            <mat-form-field class="tangy-input-width">
                <input name="role" [(ngModel)]="newRole" #role="ngModel" matInput (blur)="doesRoleExistOnGroup(newRole)"
                    placeholder="{{'Role Name'|translate}}" required>
                <span>
                    <mat-error *ngIf="(roleExists!==null&&roleExists)&&(role.dirty || role.touched)"
                        style="font-size:75%;"> {{'Role Unavailable'|translate}}
                    </mat-error>
                    <mat-error *ngIf="(roleExists!==null&&!roleExists)&&(role.dirty || role.touched)"
                        style="font-size:75%;color: green"> {{'Role Available'|translate}}</mat-error>
                    <mat-error *ngIf="(role.invalid||role.errors) && (role.dirty || role.touched)">
                        {{'This Field is Required'|translate}}
                    </mat-error>
                </span>
            </mat-form-field>
            <p *ngIf="permissionsList" class="tangy-input-width">
                <mat-checkbox #f (change)="onSelectChange(permission, f.checked)"
                    *ngFor="let permission of permissionsList" [name]="permission">
                    {{permission}}
                </mat-checkbox>
            </p>
            <p>
                <button [disabled]="addRoleForm.invalid||roleExists||roleExists===null" mat-raised-button color="warn"
                    (click)="submit()">{{'Add Role'|translate}}</button>
            </p>
        </form>
    </mat-card-content>
</mat-card>