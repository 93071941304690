
<div id="menu-container" *ngIf="rendered && formInfo && formInfo.templates && formInfo.templates.length > 0 && response && response.complete">
  <mwc-icon (click)="print()" class="print">print</mwc-icon>
  <mwc-icon [matMenuTriggerFor]="appMenu">chrome_reader_mode</mwc-icon>
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item (click)="setTemplate('')">
      Form
    </button>
    <button mat-menu-item *ngFor="let template of formTemplatesInContext" (click)="setTemplate(template.id)">
      {{template.label}}
    </button>
  </mat-menu> 
</div>
<div #container> </div>