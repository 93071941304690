<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div id="header">
  <tangy-location #tangyLocation></tangy-location>
  <div id="header-buttons">
    <paper-checkbox #showConflicts class="show-conflicts">show conflicts</paper-checkbox>
    <label id="issuesLabel">Issues:</label>
    <paper-radio-group aria-labelledby="issuesLabel" selected="Open">
      <paper-radio-button #showOpen class="show-open" name="Open">show open</paper-radio-button>
      <paper-radio-button #showMerged class="show-merged" name="Merged">show merged</paper-radio-button>
      <paper-radio-button #showClosedIssues class="show-closed-issues" name="Closed">show closed</paper-radio-button>
    </paper-radio-group>
    <paper-button id="reset-button" (click)="onResetClick()" [disabled]="loading === true">reset</paper-button>
    <paper-button id="search-button" (click)="onSearchClick()" [disabled]="loading === true">search</paper-button>
  </div>
</div>
<div class="loading" *ngIf="loading">Loading...</div>
<div class="no-issues-found" *ngIf="issues && issues.length === 0">No issues found.</div>
<div [hidden]="loading">
  <div *ngFor="let issue of issues" class="icon-list-item search-result" routerLink="{{issue._id}}">
    <mwc-icon slot="item-icon">receipt</mwc-icon>
    <div>
      <div><strong>{{issue.label}}</strong> {{moment(issue.tangerineModifiedOn).format('YYYY-MM-DD hh:mm a')}}
      </div>
      <div secondary>
        Status: {{issue.status}}
        <span *ngIf="issue.docType === 'case' && issue.events.length > 0 && issue.events[0].data.conflict">Merged: {{issue.events[0].data.conflict.merged}}</span>
        <span class="secondarySpacer" *ngIf="issue.formResponseId">Response id: {{issue.formResponseId | slice:0:8}}</span>
        <span class="secondarySpacer" *ngIf="issue.caseId">Case id: {{issue.caseId | slice:0:8}}</span>
        Device: {{issue.tangerineModifiedByDeviceId | slice:0:8}}
      </div>
    </div>
  </div>
</div>
<div id="paginator" [hidden]="loading">
  <paper-icon-button icon="arrow-back" class="previous" (click)="onPreviousClick()" [disabled]="skip === 0 || loading === true"></paper-icon-button>
  <paper-icon-button icon="arrow-forward" class="next" (click)="onNextClick()" [disabled]="loading === true || (issues && issues.length !== limit)"></paper-icon-button>
</div>
