<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div class="new-data-set-btn">
  <a routerLink="../csv-data-sets/new">
    <button mat-raised-button color="accent">
      <mwc-icon>add</mwc-icon> {{ "Request Spreadsheets" | translate }}
    </button>
  </a>
</div>

<div class="tangy-full-width">
  <div class="instructions">
    <p>
      {{ "Spreadsheets generate in CSV format and may be opened in Excel, Google Sheets, or any other Spreadsheet program. " | translate }} 
    </p>
  </div>
</div>

<div class="tangy-full-width">

  <table mat-table [dataSource]="csvDataSets" class="mat-elevation-z8 tangy-full-width">

    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef>{{ "Requested On" | translate }}</th>
      <td mat-cell *matCellDef="let dataSet">
        {{ dataSet.dateCreated | date: "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{ "Description" | translate }}</th>
      <td mat-cell *matCellDef="let dataSet">{{ dataSet?.description || "" }}</td>
    </ng-container>

   <ng-container matColumnDef="month">
      <th mat-header-cell *matHeaderCellDef>{{ "Month" | translate }}</th>
      <td mat-cell *matCellDef="let dataSet">{{ dataSet.month || "All months" }}</td>
    </ng-container>

    <ng-container matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef>{{ "Year" | translate }}</th>
      <td mat-cell *matCellDef="let dataSet">{{ dataSet.year || "All years" }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let dataSet">
        <span *ngIf="dataSet.status === 'Available'" class="available">
          {{ dataSet.status | translate }}
        </span>
        <span *ngIf="dataSet.status !== 'Available'">
          {{ dataSet.status | translate }}
        </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="numberOfSpreadsheets">
      <th mat-header-cell *matHeaderCellDef>{{ "Spreadsheets" | translate }}</th>
      <td mat-cell *matCellDef="let dataSet">{{ dataSet?.formIds.length || "" }}</td>
    </ng-container>
 
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="tangy-foreground-secondary link" [routerLink]="dataSet.id" mat-cell *matCellDef="let dataSet">
        <mwc-icon>info</mwc-icon> More info 
      </td>
    </ng-container>

    <ng-container matColumnDef="downloadUrl">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td class="tangy-foreground-secondary link" mat-cell *matCellDef="let dataSet">
        <a *ngIf="dataSet.state?.complete && dataSet.fileExists" mat-icon-button [href]="dataSet.downloadUrl">
          <i class="material-icons mat-32 tangy-location-list-icon">get_app</i> Download
        </a>
        <mat-spinner diameter=25 [hidden]="dataSet.status !== 'In progress'"></mat-spinner>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [length]="numberOfDatasets" (page)="onPageChange($event)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<div class="tangy-full-width" *ngIf="csvDataSets?.length<1 && ready">
  <div class="instructions">
    <p>{{ "CSV Data sets are a collections of CSV files - one per form - that are downloaded via a zipped file. To get started, please configure a new CSV data set using the 'New CSV Data Set' link above. " | translate }}
    </p>
    <p>{{'No Datasets available'|translate}}</p>
  </div>
</div>