<h1>{{'Manage Sitewide Permissions for'|translate}} {{username}}</h1>
<p>
    {{'Note that users will need to log out and back in to receive updated permissions.'|translate}}
</p>
<p *ngIf="userPermissions">
    <mat-checkbox 
        #f
        (change)="onSelectChange(permission, f.checked)" 
        [checked]="doesUserHavePermission(permission)"
        *ngFor="let permission of permissionsList"
        [name]="permission"
    >
        {{permission}}
    </mat-checkbox>
</p>
<p>
    <button mat-raised-button color="warn" (click)="submit()">{{'Update permissions'|translate}}</button>
</p>