<div class="mdl-color--amber color-header clearfix">
    <div class="color-explanation mdl-color-text--white">
        <h4>Welcome to Tangerine</h4>
        <h6>You are now registered</h6>
    </div>
</div>
<div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-tablet mdl-cell--12-col-phone mdl-color--grey-100">
        <div class="left-info-box">
            <!--<h5><i class="material-icons">format_align_left</i> Help</h5>-->
            <p></p>
        </div>
    </div>
    <div class="mdl-cell mdl-cell--9-col mdl-cell--12-col-tablet mdl-cell--12-col-phone r-content">
        <div class="body-content">
            <div class="mdl-card mdl-shadow--2dp change-width">
                <div class="mdl-card__title">
                    <h2 class="mdl-card__title-text">Successful Registration!</h2>
                </div>
                <div class="mdl-card__supporting-text">
                    <p>Congratulations! Your account has been activated. Please
                        <a routerLink="/login" >login</a> to manage your subscription.  </p>

                     <p>We are in the process of setting up your Tangerine instance. You will be on the free plan by default
                        (2000 results) until we receive payment for any of the paid level subscriptions. You will be emailed once the server instance is ready so you can add projects/groups and users.</p>

                    <!--<ul>
                        <li>View paid level subscriptions <a href="/plans?loggedIn=true" class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-color-text--light-blue-700">View Plans<i class="material-icons">chevron_right</i></a></li>
                        <li>Go to the credit card form for processing paid subscription <a href="/#/billing" class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-color-text--light-blue-700">Credit Cart Form<i class="material-icons">chevron_right</i></a></li>
                    </ul>-->
                    <!--<p *ngIf="user_id">You are currently logged in as <strong>{{user_id}}</strong>.</p>-->
                </div>

            </div>
        </div>
    </div>
</div>








<!--<div *ngIf="loggedIn">
       <p>Welcome to Tangerine</p>
   </div>-->

<!--<h3>Form value:</h3>
              <pre>{{value}}</pre>                -->
