<div id="filter" *ngIf="!hideFilterBy">
  <tangy-select name="form" label="Filter by form" (change)="filterByForm($event)">
    <option value="*">*</option>
    <option *ngFor="let form of forms" value="{{form.id}}">{{form.id}}</option>
  </tangy-select>
</div>
<div class="table" *ngIf="ready">
  <app-dynamic-table [data]="responses" (rowDelete)="onRowDelete($event)" (rowEdit)="onRowEdit($event)"></app-dynamic-table>
</div>
<paper-button *ngIf="this.skip !== 0" (click)="previousPage()">< back</paper-button>
<paper-button (click)="nextPage()">next ></paper-button>