<app-case-breadcrumb [caseId]="caseService.case._id"></app-case-breadcrumb>

<div  class="subject-header">
  <h1>
    <span [innerHTML]="templateTitle|unsanitizeHtml"></span>
  </h1>
  <span id="case-actions" >
    <span *ngIf="caseService.case.archived" class="archived">
      [ Archived ]
      <paper-button class="button" mat-icon-button (click)="unarchive()" *ngIf="authenticationService.doesUserHaveAPermission(groupId, 'can_unarchive_cases')">
        <i class="material-icons mat-32 tangy-location-list-icon">unarchive</i> Unarchive
      </paper-button>
    </span>
    <paper-button class="button" mat-icon-button (click)="delete()" *ngIf="authenticationService.doesUserHaveAPermission(groupId, 'can_delete_cases') && !caseService.case.archived">
      <i class="material-icons mat-32 tangy-location-list-icon">delete</i> Delete
    </paper-button>
    <paper-button class="button" mat-icon-button (click)="archive()" *ngIf="authenticationService.doesUserHaveAPermission(groupId, 'can_archive_cases') && !caseService.case.archived">
      <i class="material-icons mat-32 tangy-location-list-icon">archive</i>  Archive
    </paper-button>
  </span>
  <div secondary>
    <span [innerHTML]="templateDescription|unsanitizeHtml" class="description"></span>
    <div class="confirm-correct-case-prompt" *ngIf="caseService.openCaseConfirmed === false">
      <paper-button class="button" (click)="onOpenCaseConfirmButtonClick()">Confirm</paper-button>
    </div>
  </div>
</div>

<div class="wrapper">
  <div class="cover-when-not-confirmed" *ngIf="caseService.openCaseConfirmed === false"></div>
  <div *ngFor="let eventInfo of caseEventsInfo">
    <app-case-event-list-item *ngFor="let caseEvent of eventInfo.caseEvents" [caseEvent]="caseEvent"
      [caseDefinition]="caseService.caseDefinition" [caseEventDefinition]="eventInfo.caseEventDefinition"
      [case]="caseService.case" routerLink="/case/event/{{caseService.case._id}}/{{caseEvent.id}}">
    </app-case-event-list-item>
  </div>
  <div class="icon-list-item event new-event">
    <mwc-icon slot="item-icon">event</mwc-icon>
    <div>
      <div>{{'New Event'|translate}}</div>
      <div>
          <form (ngSubmit)="onSubmit()" #form="ngForm">
            <div class="dropdown-container">
              <select name="selected-event-type" [(ngModel)]="selectedNewEventType">
                <option value="" disabled selected>{{'Choose type...'|translate}}</option>
                <option *ngFor="let eventInfo of creatableCaseEventsInfo" [value]="eventInfo.caseEventDefinition.id">
                  {{eventInfo.caseEventDefinition.name}}</option>
              </select>
            </div>         
            <div class="text-right">
              <paper-button (click)="onSubmit()" class="button">{{'Create'|translate}}</paper-button>
            </div>
          </form>
        </div>
    </div>
    </div>
</div>

<h2 *ngIf="issues?.length > 0">Issues</h2>
<div *ngFor="let issue of issues" class="icon-list-item search-result" routerLink="/groups/{{groupId}}/data/issues/{{issue._id}}">
  <mwc-icon slot="item-icon">receipt</mwc-icon>
  <div>
    <div><strong>{{issue.label}}</strong> {{moment(issue.tangerineModifiedOn).format('YYYY-MM-DD hh:mm a')}}
    </div>
    <div secondary>
      Status: {{issue.status}}
      <span *ngIf="issue.docType === 'case' && issue.events.length > 0 && issue.events[0].data.issue">Merged: {{issue.events[0].data.issue.merged}}</span>
      <span class="secondarySpacer" *ngIf="issue.formResponseId">Response id: {{issue.formResponseId | slice:0:8}}</span>
      <span class="secondarySpacer" *ngIf="issue.caseId">Case id: {{issue.caseId | slice:0:8}}</span>
      Device: {{issue.tangerineModifiedByDeviceId | slice:0:8}}
    </div>
  </div>
</div>

<div *appHasAPermission="let i;group:groupId; permission:'can_restore_conflict_event'">
  <h2 *ngIf="conflictingEvents?.length > 0">Events in Conflict</h2>
  <div *ngFor="let conflict of conflictingEvents" class="icon-list-item conflict new-event">
    <mwc-icon slot="item-icon">receipt</mwc-icon>
    <div>
      <div><strong>{{conflict.name}}</strong></div>
      <div>
        <div secondary>
          Complete: {{conflict.complete}}
          <span *ngFor="let eventForm of conflict.eventForms">
            ID: {{eventForm.formResponseId | slice:0:8}} Rev: {{conflict.conflictRevisionId| slice:0:8}}
          </span>
        </div>
        <div class="text-right">
  <!--        <ng-container *ngFor="let eventForm of conflict.eventForms">-->
  <!--          <ng-container *ngIf="eventForm.formResponseId">-->
  <!--            <paper-button (click)="showConflictFormResponse(eventForm.formResponseId)" class="button-left">View</paper-button>-->
  <!--          </ng-container>-->
  <!--        </ng-container>-->
          <paper-button (click)="onRestore(conflict)" [hidden]="hideRestore" class="button">{{'Restore'|translate}}</paper-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <app-tangy-forms-player [skipSaving]="true" #proposedFormResponseContainer></app-tangy-forms-player>
</div>
