<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div id="container">
  <div *ngIf="!processing" class="tangy-full-width">
    <form class="form">
      <div>
        <mat-form-field appearance="fill" color="primary">
          <mat-label>{{'Month'|translate}}</mat-label>
          <mat-select name="selectedMonth" class="month" [(ngModel)]="selectedMonth" >
            <mat-option value="*" selected="selected">*</mat-option>
            <mat-option *ngFor="let month of months" value="{{month}}">{{month}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" color="primary">
          <mat-label>{{'Year'|translate}}</mat-label>
          <mat-select name="selectedYear" class="year" [(ngModel)]="selectedYear">
            <mat-option value="*" selected="selected">*</mat-option>
            <mat-option *ngFor="let year of years" value="{{year}}">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox name="excludePII"  [(ngModel)]="excludePII">{{'Exclude PII?'|translate}}
        </mat-checkbox>
        <paper-button class="button" (click)="process()">submit</paper-button>
      </div>
    </form>
  </div>
  <p class="tangy-full-width" *ngIf="processing && !result">{{'Loading...'|translate}}</p>
  <div *ngIf="processing && result && nothingToDownload === false" class="tangy-full-width">
    <h4 *ngIf="!isDownloadComplete&&!errorDownloadingFile">{{'Generating CSV'|translate}}</h4>
    <h4 *ngIf="errorDownloadingFile">{{'Error Downloading File'|translate}}</h4>
    <p>{{'Group Name'|translate}}: {{groupName}}</p>
    <p>{{'Form Id'|translate}}: {{formId}}</p>
    <p>{{'Start Time'|translate}}: {{result.startTime|date:'medium'}}</p>
    <p>{{'Progress'|translate}}: {{result.skip}}</p>
    <div *ngIf="isDownloadComplete">
      <a target="_blank" href="{{downloadUrl}}"><paper-button class="button download" >{{'download'|translate}}</paper-button></a>
    </div>
  </div>
  <div *ngIf="selectedMonth === '*' && processing && result && nothingToDownload === true" class="tangy-full-width">
    There are no form responses for this form.
  </div>
  <div *ngIf="selectedMonth !== '*' && processing && result && nothingToDownload === true" class="tangy-full-width">
    There are no form responses for this form in the month and year that you selected.
  </div>
</div>
