<mat-tab-group class="tangy-full-width" [selectedIndex]="selectedIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        {{'Form Editor'|translate}}
      </ng-template>
      <div #container></div>
    </mat-tab>
    <mat-tab *ngIf="hasClassModule">
      <ng-template mat-tab-label>
        {{'Feedback Editor'|translate}}
      </ng-template>
      <feedback-editor [groupName]="groupId" [formId]="formId"></feedback-editor>
    </mat-tab>
</mat-tab-group>