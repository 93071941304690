<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<form class="tangy-full-width" novalidate #updateUserRoleForm="ngForm">
    <div>
        <p>{{'Update Role of' | translate}}: {{username}}</p>

    </div>
    <p *ngIf="allRoles" class="tangy-input-width">
        <mat-checkbox #f (change)="onSelectChange(role.role, f.checked)"
            [checked]="doesUserHaveRole(role.role)" *ngFor="let role of allRoles" [name]="role.role">
            {{role.role}}
        </mat-checkbox>
    </p>
    <p *ngIf="allRoles.length<1" class="tangy-input-width">{{'No Roles exist yet. '|translate}}</p>
    <button [disabled]="!username" mat-raised-button color="warn"
        (click)="addUserToGroup();updateUserRoleForm.reset()">{{"submit"|translate}}</button>
</form>