<div class="tangy-content tangy-full-width">
    <div>
      <div #container></div>
      {{'Group'|translate}}: {{groupDetails?.label}}<br>
      {{'Group ID'}}: {{groupDetails?._id}}<br>
      {{'Form'|translate}}: {{meta?.form?.title}}
    </div>
    <br><br><br>
  <div>
      <div style="page-break-after: always;" *ngFor="let section of meta?.items">
        <p style="font-size: 32px;" *ngFor="let input of section.inputs; let index=index">{{index+1}}. {{input.label}}</p>
      </div>
    </div>
</div>