 <div class="tangy-content tangy-full-width">
    <div>
      <div #container></div>
      {{'Group'|translate}}: {{groupDetails?.label}}<br>
      {{'Group ID'}}: {{groupDetails?._id}}<br>
      {{'Form'|translate}}: {{meta?.form.title}}
    </div>
    <br><br><br>
      <p style="page-break-after: always;" *ngFor="let section of meta?.items">
        <span style="font-size: 32px;" *ngFor="let input of section.inputs; let index=index">
          {{input.label}}
          <span *ngIf="input.gridItem">{{input.name}}</span>
          <br><br>
          <table >
          <ng-container *ngIf="input.gridItem">
            <tr *ngFor="let parts of input.gridParts">
              <td *ngFor="let part of parts">{{part.label +'  '}}</td>
            </tr>
          </ng-container>
          </table>
      </span>
      </p>
  </div>