<div class="wrapper">
  <div class="form-cards">
    <div *ngFor='let query of queries' class="icon-list-item form new-form">
      <mwc-icon [innerHTML]="renderedTemplateListItemIcon" slot="item-icon"></mwc-icon>
      <div>
        <div><span>{{ query.queryText }}</span> </div>
        <div secondary><strong>Case ID:</strong> {{ query.associatedCaseName }}&nbsp;&nbsp;<strong>Event:</strong> {{ query.associatedEventName }}&nbsp;&nbsp;<strong>Form:</strong> {{ query.associatedFormName }}&nbsp;&nbsp;<strong>Status:</strong> {{ query.queryStatus }}</div>
      </div>
      <button  routerLink = '{{ query.queryLink }}' type="submit">{{ query.queryStatus === 'Open' ? 'View Query' : 'View Response'}}</button>&nbsp;&nbsp;<button routerLink = '{{ query.associatedFormLink }}' type="submit">Open Form</button>
    </div>

  </div>
</div>