<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div id="container">
  <tangy-radio-buttons 
    (change)="onPasswordPolicyCategorySelect()" 
    label="Password Policy"
    #passwordPolicyCategoryEl
  >
    <option value="no_password">No password</option>
    <option value="no_password_policy">Password required but no password policy</option>
    <option value="strong_password_policy">Password required, strong password policy</option>
    <option value="custom_password_policy">Password required, custom password policy</option>
  </tangy-radio-buttons>
  <div id="password-configuration">
    <paper-input 
      *ngIf="passwordPolicyCategory !== 'no_password'"
      label="Password Policy Description" 
      #passwordPolicyDescriptionEl
      [value]="passwordPolicyDescription"
    >
    </paper-input>
    <paper-input
      *ngIf="passwordPolicyCategory !== 'no_password' && passwordPolicyCategory !== 'no_password_policy'"
      label="Password Validator in RegEx" 
      #passwordPolicyRegexEl
      [value]="passwordPolicyRegex"
    >
    </paper-input>
    <paper-input
      *ngIf="passwordPolicyCategory !== 'no_password'"
      label="Account recovery question" 
      #accountRecoveryQuestionEl
      [value]="accountRecoveryQuestion"
    >
    </paper-input>
  </div>
  <paper-button (click)="submit()">Submit</paper-button>
</div>