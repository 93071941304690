<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<mat-card class="is-link mat-elevation-z3" routerLink="forms" *appHasAPermission="let i;group:groupId; permission:'can_access_forms'">
  <mat-card-header>
      <div mat-card-avatar>
        <i class="material-icons tangy-foreground-secondary group-avatar">assignment</i>
      </div>
      <mat-card-title>
          <a>{{'Forms'|translate}}</a>
      </mat-card-title>
      <mat-card-subtitle>Create and edit forms.</mat-card-subtitle>
  </mat-card-header>
</mat-card>

<mat-card class="is-link mat-elevation-z3" routerLink="media-library" *appHasAPermission="let i;group:groupId; permission:'can_access_media'">
  <mat-card-header>
      <div mat-card-avatar>
        <i class="material-icons tangy-foreground-secondary group-avatar">perm_media</i>
      </div>
      <mat-card-title>
          <a>{{'Media Library'|translate}}</a>
      </mat-card-title>
      <mat-card-subtitle>Manage media library available for use in forms.</mat-card-subtitle>
  </mat-card-header>
</mat-card>