<div class="tangy-main-container" [class.tangy-main-is-mobile]="mobileQuery.matches">

    <mat-sidenav-container class="tangy-main-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav #snav [class.mat-drawer-opened]="snav.opened" [mode]="mobileQuery.matches ? 'over' : 'side'">
            <mat-nav-list class="tangy-full-width tangerine-logo">
                <mat-list-item routerLink="/">
                    <!--img class="tangerine-logo-closed" src="/assets/img/solo-tangerine.png"-->
                    <svg class="tangerine-logo-closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.41 83.43">
                        <defs>
                            <style>
                                .cls-1 {
                                    fill: #fcb814;
                                }

                                .cls-2 {
                                    fill: #f8f185;
                                }

                                .cls-3 {
                                    fill: #f2682c;
                                }

                                .cls-4 {
                                    fill: #f47820;
                                }

                                .cls-5 {
                                    fill: #f2672b;
                                }

                                .cls-6 {
                                    fill: #fff;
                                }
                            </style>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path
                                    d="M35.79 6.93a3.24 3.24 0 0 1 4.62 3.15l.71 25.74c.07 2.62-1.2 3.09-2.83 1l-16-20.22a3.23 3.23 0 0 1 1.52-5.36z"
                                    class="cls-1"></path>
                                <circle cx="41.71" cy="41.72" r="40.28" class="cls-2"
                                    transform="rotate(-44.86 41.725 41.72)"></circle>
                                <path
                                    d="M41.72 83.43a41.72 41.72 0 0 1-29.43-71.28 41.72 41.72 0 1 1 58.85 59.13 41.45 41.45 0 0 1-29.42 12.15zm0-80.57a38.85 38.85 0 0 0-27.54 66.26 38.85 38.85 0 1 0 55.07-54.81A38.59 38.59 0 0 0 41.71 2.86z"
                                    class="cls-3"></path>
                                <path
                                    d="M35.12 78.08a3.25 3.25 0 0 1-3.26-4.54L40 49.11c.83-2.49 2.19-2.49 3 0l8.15 24.49a3.23 3.23 0 0 1-3.26 4.52z"
                                    class="cls-4"></path>
                                <path
                                    d="M59.5 74a3.24 3.24 0 0 1-5.42-1.38L44.61 48.7c-1-2.44.07-3.31 2.31-1.94l22 13.52a3.23 3.23 0 0 1 .4 5.56z"
                                    class="cls-1"></path>
                                <path
                                    d="M75.56 55.24a3.25 3.25 0 0 1-5 2.43L47.88 45.41c-2.31-1.25-2.07-2.58.52-3l25.52-3.77a3.23 3.23 0 0 1 3.88 4z"
                                    class="cls-5"></path>
                                <path
                                    d="M75.8 30.53a3.25 3.25 0 0 1-2.3 5.09l-25.23 5.17c-2.57.53-3.25-.65-1.51-2.61l17.13-19.29a3.23 3.23 0 0 1 5.54.57z"
                                    class="cls-4"></path>
                                <path
                                    d="M60.09 11.45a3.24 3.24 0 0 1 1.52 5.38L45.6 37c-1.63 2.06-2.9 1.59-2.83-1l.72-25.79a3.23 3.23 0 0 1 4.61-3.15z"
                                    class="cls-5"></path>
                                <path
                                    d="M35.79 6.93a3.24 3.24 0 0 1 4.62 3.15l.71 25.74c.07 2.62-1.2 3.09-2.83 1l-16-20.22a3.23 3.23 0 0 1 1.52-5.36z"
                                    class="cls-1"></path>
                                <path
                                    d="M14.28 19.08a3.24 3.24 0 0 1 5.56-.56l17.09 19.27c1.74 2 1.06 3.14-1.51 2.61l-25.27-5.19a3.23 3.23 0 0 1-2.28-5.09z"
                                    class="cls-5"></path>
                                <path
                                    d="M5.6 42.23a3.24 3.24 0 0 1 3.9-4L35 42c2.6.38 2.83 1.72.52 3L12.81 57.24a3.23 3.23 0 0 1-5-2.43z"
                                    class="cls-4"></path>
                                <path
                                    d="M13.84 65.53a3.24 3.24 0 0 1 .41-5.53l21.94-13.53c2.24-1.37 3.27-.5 2.31 1.94l-9.5 24a3.23 3.23 0 0 1-5.41 1.36z"
                                    class="cls-1"></path>
                                <path
                                    d="M26 74.83a3.84 3.84 0 0 1-2.46-1l-9.76-8.23a3.36 3.36 0 0 1 .43-5.77l21.92-13.46A3.73 3.73 0 0 1 38 45.7a1 1 0 0 1 .83.39c.32.46.26 1.28-.17 2.36l-9.5 24A3.42 3.42 0 0 1 26 74.83zm12-28.89a3.56 3.56 0 0 0-1.7.63L14.31 60.06a3.13 3.13 0 0 0-.4 5.38l9.76 8.23a3.6 3.6 0 0 0 2.3.93 3.19 3.19 0 0 0 2.91-2.24l9.5-24c.39-1 .46-1.75.2-2.14a.71.71 0 0 0-.58-.28z"
                                    class="cls-1"></path>
                                <circle cx="41.72" cy="42.53" r="2.06" class="cls-5"
                                    transform="rotate(-44.86 41.72 42.527)"></circle>
                                <path
                                    d="M10.36 30.55c.42-.9 1.7-.75 2.84.33s.8 1.82-.77 1.64-2.5-1.06-2.07-1.97zm14.99 1.14c.42-.9 1.7-.75 2.85.33s.8 1.82-.77 1.64-2.5-1.07-2.08-1.97zm-1.5 36.02c-.83-.55-.5-1.79.74-2.77s1.91-.53 1.51 1-1.42 2.32-2.25 1.77zM54.39 57.6c-.82.56-1.85-.22-2.28-1.73s.24-2 1.49-1 1.61 2.13.79 2.73z"
                                    class="cls-6"></path>
                            </g>
                        </g>
                    </svg>
                    <img class="tangerine-logo-opened" src="/assets/tangerine-logo-box.png">
                </mat-list-item>
            </mat-nav-list>
            <mat-nav-list class="tangy-full-width"  *ngIf="menuService.groupId">
                <mat-list-item 
                    [class.active-menu-item]="menuService.section === 'author'"
                    routerLink="/groups/{{menuService.groupId}}/author"
                    *appHasAPermission="let i;group:menuService.groupId; permission:'can_access_author'"
                >
                    <span class=""><i class="material-icons menu-icon">create</i></span>
                    <a matLine>{{'Author'|translate}}</a>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-nav-list>
            <mat-nav-list class="tangy-full-width" *ngIf="menuService.groupId">
                <mat-list-item
                    [class.active-menu-item]="menuService.section === 'data'"
                    routerLink="/groups/{{menuService.groupId}}/data"
                    *appHasAPermission="let i;group:menuService.groupId; permission:'can_access_data'"
                >
                    <span class=""><i class="material-icons menu-icon list_alt-icon">list_alt</i></span>
                    <a matLine>{{'Data'|translate}}</a>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-nav-list>
            <mat-nav-list class="tangy-full-width" *ngIf="menuService.groupId">
                <mat-list-item
                    [class.active-menu-item]="menuService.section === 'configure'"
                    routerLink="/groups/{{menuService.groupId}}/configure"
                    *appHasAPermission="let i;group:menuService.groupId; permission:'can_access_configure'"
                >
                    <span class=""><i class="material-icons menu-icon">settings_applications</i></span>
                    <a matLine>{{'Configure'|translate}}</a>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-nav-list>
            <mat-nav-list class="tangy-full-width" *ngIf="menuService.groupId">
                <mat-list-item 
                    [class.active-menu-item]="menuService.section === 'deploy'"
                    routerLink="/groups/{{menuService.groupId}}/deploy"
                    *appHasAPermission="let i;group:menuService.groupId; permission:'can_access_deploy'"
                >
                <span class=""><i class="material-icons menu-icon">phonelink_ring</i></span>
                <a matLine>{{'Deploy'|translate}}</a>
            </mat-list-item>
            <mat-divider></mat-divider>
            </mat-nav-list>
            <mat-divider></mat-divider>
            <mat-nav-list class="tangy-full-width">
                <mat-list-item *ngIf="loggedIn && menuService.groupId === ''"
                    [class.active-menu-item]="menuService.section === 'groups'" routerLink="/">
                    <span class=""><i class="material-icons menu-icon">group_work</i></span>
                    <a matLine>{{'Groups'|translate}}</a>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-nav-list>
            <mat-nav-list class="tangy-full-width" *ngxPermissionsOnly="['can_view_users_list']">
                <mat-list-item *ngIf="loggedIn &&  menuService.groupId === ''"
                    class="manage-users-list-item" [class.active-menu-item]="menuService.section === 'users'"
                    routerLink="/users">
                    <span class=""><i class="material-icons menu-icon">group</i></span>
                    <a matLine>{{'Users'|translate}}</a>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-nav-list>
            <div id="sidenav-bottom-group">
                <!-- groups link in the group context -->
                <mat-nav-list class="tangy-full-width">
                    <mat-list-item *ngIf="loggedIn && menuService.groupId !== ''" routerLink="/">
                        <span class=""><i class="material-icons menu-icon">group_work</i></span>
                        <a matLine>{{'Groups'|translate}}</a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-nav-list>
                <!-- Help link in the group context -->
                <mat-nav-list class="tangy-full-width">
                    <mat-list-item *ngIf="loggedIn && menuService.groupId"
                        [class.active-menu-item]="menuService.section === 'help'"
                        routerLink="/groups/{{menuService.groupId}}/support">
                        <span class=""><i class="material-icons menu-icon">help</i></span>
                        <a matLine>{{'Help'|translate}}</a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-nav-list>
                <!-- Help link in the global context -->
                <mat-nav-list class="tangy-full-width" *ngIf="loggedIn">
                    <mat-list-item *ngIf="!menuService.groupId"
                        [class.active-menu-item]="menuService.section === 'help'" routerLink="/support">
                        <span class=""><i class="material-icons menu-icon">help</i></span>
                        <a matLine>{{'Help'|translate}}</a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item (click)="logout();">
                        <span class=""><i class="material-icons menu-icon">exit_to_app</i></span>
                        <a matLine>{{'Logout'|translate}}</a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </mat-nav-list>
            </div>
        </mat-sidenav>


        <mat-sidenav-content [class.mat-drawer-opened]="snav.opened">
            <mat-toolbar class="tangy-main-toolbar mat-elevation-z5">
                <button mat-icon-button (click)="snav.toggle()" *ngIf="loggedIn">
                    <mat-icon>menu</mat-icon>
                </button>
                <div class="tangy-main-app-name" *ngIf="loggedIn">
                    {{menuService.title}}
                </div>
                <span class="toolbar-spacer"></span>
                <span *ngxPermissionsOnly="['non_user1_user']">
                    <a routerLink="/update-personal-profile" mat-button class="account-button" *ngIf="loggedIn">
                        <i class="material-icons">account_circle</i> <span>{{user_id}}</span>
                    </a>
                </span>
                <span *ngxPermissionsExcept="['non_user1_user']">
                    <a mat-button class="account-button" *ngIf="loggedIn">
                        <i class="material-icons">account_circle</i> <span>{{user_id}}</span>
                    </a>
                </span>
            </mat-toolbar>
          <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>