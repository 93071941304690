<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<div id="download-all-container" >
  <button mat-raised-button color="warn" *ngIf="datasetDetail?.status === 'Available' " class="download-all-button" (click)="download(datasetDetail.downloadUrl)">
    <i class="material-icons mat-32 tangy-location-list-icon">get_app</i>
    {{'download all'|translate}}
  </button>
 <button mat-raised-button color="warn" *ngIf="datasetDetail?.status === 'In progress'" disabled>
    <mat-spinner diameter=25></mat-spinner> {{'download all'|translate}}
  </button>
</div>
<div *ngIf="datasetDetail && !stateUnavailable" class="tangy-full-width">
    <p><strong>{{"Status"|translate}}:</strong> {{datasetDetail?.status}}</p>
    <p [hidden]="!datasetDetail?.description"><strong>{{'Description'|translate}}</strong>: {{datasetDetail?.description}}</p>    
    <p><strong>{{'Exclude PII'|translate}}</strong>: 
      {{datasetDetail.excludePii ? 'true' : 'false'}}
    </p>
    <p><strong>{{'Month'|translate}}:</strong> {{datasetDetail?.month === '*' ? 'All months' : datasetDetail?.month }}</p>
    <p><strong>{{'Year'|translate}}:</strong> {{datasetDetail?.year === '*' ? 'All years' : datasetDetail?.year }}</p>
</div>
<table *ngIf="datasetDetail && !stateUnavailable"
    mat-table
    [dataSource]="datasetDetail.csvs"
    class="mat-elevation-z8 full-width"
  >
    <ng-container matColumnDef="formTitle">
      <th mat-header-cell *matHeaderCellDef>{{'Form'|translate}}</th>
      <td mat-cell *matCellDef="let dataSet">
        {{ dataSet|formTitleFromInfo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="csvTemplateTitle">
      <th mat-header-cell *matHeaderCellDef>{{'Spreadsheet Template'|translate}}</th>
      <td mat-cell *matCellDef="let dataSet">{{ dataSet?.csvTemplateTitle }}</td>
    </ng-container>
    
    <ng-container matColumnDef="inProgress">
      <th mat-header-cell *matHeaderCellDef>{{'Status'|translate}}</th>
      <td mat-cell *matCellDef="let dataSet">
        {{ dataSet?.inProgress ? dataSet?.skip : ''}} {{ dataSet?.inProgress && dataSet.skip ? 'rows processed' : ''}}  {{ dataSet?.inProgress && !dataSet.skip ? 'Starting...' : ''}}
        {{ dataSet?.complete ? 'Complete' : '' | translate}}
        {{ !dataSet?.complete && !dataSet?.inProgress ? 'Queued' : '' | translate}}
      </td>
    </ng-container>
    
    <ng-container matColumnDef="outputPath">
      <th mat-header-cell *matHeaderCellDef>{{'Download File'|translate}}</th>
      <td mat-cell *matCellDef="let dataSet">
        <mat-spinner diameter=25 [hidden]="dataSet?.complete || !dataSet?.inProgress"></mat-spinner>
        <a *ngIf="dataSet?.complete" mat-icon-button [href]="datasetDetail?.baseUrl+ dataSet?.outputPath">
          <i class="material-icons mat-32 tangy-location-list-icon">get_app</i> download file
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

<div *ngIf="stateUnavailable" class="tangy-full-width">
  <p>{{'Looking for data...'|translate}}</p>
</div>