<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div id="description">
  {{'Spreadsheet Templates allow you to define which columns and the order of columns on a Spreadsheet.'|translate}}
</div>
<app-dynamic-table 
  *ngIf="csvTemplates.length > 0"
  [data]="csvTemplates" 
  (rowDelete)="onRowDelete($event)" 
  (rowEdit)="onRowEdit($event)"
>
</app-dynamic-table>
<div *ngIf="csvTemplates.length === 0" id="no-spreadsheets">
  {{'No Spreadsheet Templates have been created. Click the + icon in the bottom right to get started.'|translate}}
</div>
<paper-fab (click)="createCsvTemplate()" mat-raised-button icon="add" color="accent" class="action">
</paper-fab>
