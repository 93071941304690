import { Component, OnInit } from '@angular/core';

@Component({
  //selector: 'app-verify',
  templateUrl: './verify.component.html',
  //styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
      console.log('hello verify page');
  }

}