<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div style="margin-left: 15px;">
  <h2>Instructions: </h2>

  <div>
    In the Unpublished Surveys section, click the
    <button class="info-button">
      <i class="material-icons tangy-location-list-icon">published_with_changes</i>
    </button>
    button to "Publish" an online survey. It will then be listed in the Published Surveys section.
  </div>

  <div>
    In the Published Surveys section, use the link
    <button class="info-button">
      <i class="material-icons tangy-location-list-icon">link</i>
    </button> 
    to share the survey with users.
      Use the
      <button class="info-button">
        <i class="material-icons tangy-location-list-icon">unpublished</i>
      </button>
      button to "Un-publish" an online survey.
  </div>
</div>
<h2 class="tangy-foreground-secondary">{{'Published Surveys'|translate}}</h2>
<mat-list class="drag-list">
  <mat-list-item class="drag-item" *ngFor="let form of publishedSurveys; let index=index">
    <span>{{index+1}}</span>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span class="tangy-spacer" [innerHTML]="form.title|unsanitizeHtml"></span>
    <span>{{form.updatedOn|date :'medium'}}
    </span>

    <span *appHasAPermission="let i;group:group._id; permission:'can_manage_forms'" style="width: 5em">
      <button mat-icon-button (click)="unPublishSurvey(form.id)" class="unpublished-button">
        <i class="material-icons mat-32 tangy-location-list-icon">unpublished</i>
      </button>
    </span>
    <span class="link"><a href="/releases/prod/online-survey-apps/{{group._id}}/{{form.id}}/#/form/{{form.id}}" target="_new"> <i class="material-icons mat-32 tangy-location-list-icon">link</i></a></span>
  </mat-list-item>
</mat-list>

<h2 class="tangy-foreground-secondary">{{'Unpublished Surveys'|translate}}</h2>
<mat-list class="drag-list">
  <mat-list-item class="drag-item" *ngFor="let form of unPublishedSurveys; let index=index">
    <span>{{index+1}}</span>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span class="tangy-spacer" [innerHTML]="form.title|unsanitizeHtml"></span>

    <span *appHasAPermission="let i;group:group._id; permission:'can_manage_forms'">
      <button mat-icon-button (click)="publishSurvey(form.id, form.title)">
        <i class="material-icons mat-32 tangy-location-list-icon">published_with_changes</i>
      </button>
    </span>

    <span >{{form.updatedOn|date :'medium'}}
    </span>
  </mat-list-item>
</mat-list>
