<div *ngIf="loaded">
  <app-case-breadcrumb *ngIf="caseService && caseService.case && caseService.case._id" [caseId]="caseService.case._id" [caseEventId]="caseEvent.id"></app-case-breadcrumb>
  <app-case-notifications></app-case-notifications>
  <div class="wrapper">
    <div class="screen" *ngIf="hasNotificationEnforcingAttention"></div>
    <app-event-form-list-item
      *ngFor="let eventFormInfo of noRoleEventFormInfos"
      [case]="caseService.case"
      [caseDefinition]="caseService.caseDefinition"
      [caseEvent]="caseEvent"
      [caseEventDefinition]="caseEventDefinition"
      [eventForm]="eventFormInfo.eventForm"
      [eventFormDefinition]="eventFormInfo.eventFormDefinition"
      class="form {{eventFormInfo.eventFormDefinition.required ? 'required' : ''}}" 
      routerLink="/case/event/form/{{eventFormInfo.eventForm.caseId}}/{{eventFormInfo.eventForm.caseEventId}}/{{eventFormInfo.eventForm.id}}"
    >
    </app-event-form-list-item>
    <app-event-forms-for-participant
      [caseId]="caseService.case._id"
      [eventId]="caseEvent.id"
      [participantId]="participant.id"
      *ngFor="let participant of caseService.case.participants"
    >
    </app-event-forms-for-participant>
     
  </div>
</div>