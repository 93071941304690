<h1>Looking for help using Tangerine?</h1>
<div id="content">
    <p>
        Please check out these resources:
    </p>
    <ul>
        <li><a href="https://tangerinehelp.zendesk.com/hc/en-us" target="_blank">Tangerine FAQ's</a></li>
    </ul>

    <p>
        Couldn't find what you're looking for? Contact us:
        <a href="mailto:support@tangerinehelp.zendesk.com">support@tangerinehelp.zendesk.com</a>
    </p>
    <p>
        Tangerine Version: {{version}}
    </p>
</div>