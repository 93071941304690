<!-- Generated using loading.io -->
<div>
  <div class="lds-css ng-scope">
    <div class="lds-spinner" style="100%;height:100%">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <style type="text/css">
      @keyframes lds-spinner {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }

      @-webkit-keyframes lds-spinner {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }

      .lds-spinner {
        position: relative;
      }

      .lds-spinner div {
        left: 95px;
        top: 57px;
        position: absolute;
        -webkit-animation: lds-spinner linear 1s infinite;
        animation: lds-spinner linear 1s infinite;
        background: #f05125;
        width: 10px;
        height: 22px;
        border-radius: 196%;
        -webkit-transform-origin: 5px 43px;
        transform-origin: 5px 43px;
      }

      .lds-spinner div:nth-child(1) {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }

      .lds-spinner div:nth-child(2) {
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }

      .lds-spinner div:nth-child(3) {
        -webkit-transform: rotate(72deg);
        transform: rotate(72deg);
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
      }

      .lds-spinner div:nth-child(4) {
        -webkit-transform: rotate(108deg);
        transform: rotate(108deg);
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
      }

      .lds-spinner div:nth-child(5) {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }

      .lds-spinner div:nth-child(6) {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
      }

      .lds-spinner div:nth-child(7) {
        -webkit-transform: rotate(216deg);
        transform: rotate(216deg);
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }

      .lds-spinner div:nth-child(8) {
        -webkit-transform: rotate(252deg);
        transform: rotate(252deg);
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
      }

      .lds-spinner div:nth-child(9) {
        -webkit-transform: rotate(288deg);
        transform: rotate(288deg);
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }

      .lds-spinner div:nth-child(10) {
        -webkit-transform: rotate(324deg);
        transform: rotate(324deg);
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }

      .lds-spinner {
        width: 89px !important;
        height: 89px !important;
        -webkit-transform: translate(-44.5px, -44.5px) scale(0.445) translate(44.5px, 44.5px);
        transform: translate(-44.5px, -44.5px) scale(0.445) translate(44.5px, 44.5px);
      }
    </style>
  </div>
</div>